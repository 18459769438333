export enum SectionId {
  NAVIGATION = "navigation",
  HOME_PAGE = "home",
  SERVICES = "services",
  PRICING = "pricing",
  FAQ = "faq",
  CONTACT_US = "contact-us",
}

export enum LinkModule {
  PORTAL = "portal",
  PUBLIC = "public",
}

export enum ButtonType {
  FILLED = "filled",
  OUTLINED = "outlined",
}

export enum TextSize {
  DEFAULT = "default",
  SMALL = "small",
}

export enum SupportedPlatform {
  ANDROID = "android",
  IOS = "ios",
  AMAZONE = "amazone",
  FIRESTICK = "firestick",
}

export enum IframeMessageType {
  REDIRECT = "redirect",
  SCROLL = "scroll",
  OPEN_CHAT = "openChat",
}
